/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack } from "@mui/system";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";

import { usePopover } from "src/hooks/use-popover";

export const FilterDatePicker = ({
  label,
  isRange = false,
  filter,
  setFilter,
  filter2,
  setFilter2,
  subLabel1,
  subLabel2,
  setNonFilter,
  setNonFilter2,
  labelFontSize,
}) => {
  const popover = usePopover();

  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const [date2, setDate2] = useState(null);
  const [time2, setTime2] = useState(null);

  const [value2, setValue2] = useState("");

  useEffect(() => {
    if (filter2) {
      setValue2(filter2);
    }
  }, [filter, filter2]);

  useEffect(() => {
    const date = new Date();
    const endDate = new Date();
    date.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    setDate(date);
    setTime(date);
    setDate2(endDate);
    setTime2(endDate);
  }, []);

  return (
    <>
      <Button
        color="inherit"
        endIcon={
          <SvgIcon>
            <ChevronDownIcon />
          </SvgIcon>
        }
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{ px: 0, py: 0 }}
      >
        <Typography fontSize={labelFontSize ?? 14} fontWeight="600" noWrap>
          {label ? label?.toUpperCase() : ""}
        </Typography>
      </Button>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ style: { px: 10, width: 250 } }}
      >
        <Stack spacing={1}></Stack>
        <Stack spacing={1}></Stack>
        <Stack sx={{ px: 2, py: 1 }} direction="row">
          <Stack gap={1}>
            <DatePicker
              format="yyyy-MM-dd"
              label={
                subLabel1
                  ? subLabel1
                    ? `${subLabel1} Date`
                    : ""
                  : `${label} Date`
              }
              value={date ? new Date(date) : new Date()}
              onChange={(val) => setDate(val)}
            />
            <TimePicker
              format="h:mm a"
              label={
                subLabel1
                  ? subLabel1
                    ? `${subLabel1} Time`
                    : ""
                  : `${label} Time`
              }
              value={time ? new Date(time) : new Date()}
              onChange={(val) => setTime(val)}
            />
          </Stack>
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            {setNonFilter ? (
              <Tooltip title="Default">
                <IconButton
                  sx={{ ml: 1 }}
                  color="primary"
                  onClick={() => {
                    const result = `${format(date, "yyyy-MM-dd")} ${format(
                      time,
                      "HH:mm"
                    )}`;
                    setFilter(result ?? "");
                  }}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                sx={{ ml: 1 }}
                color="primary"
                onClick={() => {
                  const result = `${format(date, "yyyy-MM-dd")} ${format(
                    time,
                    "HH:mm"
                  )}`;
                  setFilter(result ?? "");
                }}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            )}
            {setNonFilter ? (
              <Tooltip title="Exclude">
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {
                    const result = `${format(date, "yyyy-MM-dd")} ${format(
                      time,
                      "HH:mm"
                    )}`;
                    setNonFilter(result ?? "");
                  }}
                >
                  <RemoveCircleOutlineIcon fontSize="small" color="gray" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
        </Stack>
        {isRange ? (
          <Stack sx={{ px: 2, py: 1 }} direction="row">
            <Stack gap={1}>
              <DatePicker
                format="yyyy-MM-dd"
                label={subLabel2 ? `${subLabel2} Date` : ""}
                value={date2 ? new Date(date2) : new Date()}
                onChange={(val) => setDate2(val)}
              />
              <TimePicker
                format="h:mm a"
                label={subLabel2 ? `${subLabel2} Time` : ""}
                value={time2 ? new Date(time2) : new Date()}
                onChange={(val) => setTime2(val)}
              />
            </Stack>
            <Stack
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              {setNonFilter2 ? (
                <Tooltip title="Default">
                  <IconButton
                    sx={{ ml: 1 }}
                    color="primary"
                    onClick={() => {
                      const result = `${format(date2, "yyyy-MM-dd")} ${format(
                        time2,
                        "HH:mm"
                      )}`;
                      setFilter2(result ?? "");
                    }}
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  sx={{ ml: 1 }}
                  color="primary"
                  onClick={() => {
                    const result = `${format(date2, "yyyy-MM-dd")} ${format(
                      time2,
                      "HH:mm"
                    )}`;
                    setFilter2(result ?? "");
                  }}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              )}
              {setNonFilter2 ? (
                <Tooltip title="Exclude">
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                      const result = `${format(date2, "yyyy-MM-dd")} ${format(
                        time2,
                        "HH:mm"
                      )}`;
                      setNonFilter2(result ?? "");
                    }}
                  >
                    <RemoveCircleOutlineIcon fontSize="small" color="gray" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
          </Stack>
        ) : null}
      </Menu>
    </>
  );
};
